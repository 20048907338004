import React from 'react';
import { Helmet } from 'react-helmet-async';
import { graphql, PageRendererProps } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { v4 } from 'uuid';

import { getMimeTypeFromExtension } from '../utils';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import EventDate from '../components/EventDate';

interface TemplateProps {
  title: string;
  location?: string;
  schema?: object;
  image: string | FluidObject & { width: number; height: number };
  focalPoint: { x: number; y: number };
  heading: string;
  subheading: string;
  categories: {
    title: string;
    subtitle?: string;
    schedules?: {
      startDate: string;
      endDate: string;
      location: string;
      event: string;
    }[];
  }[];
}

interface PageProps extends PageRendererProps {
  data: {
    markdownRemark: {
      frontmatter: {
        type: string;
        title: string;
        heroImage: {
          image: {
            childImageSharp: {
              hero: FluidObject & { width: number; height: number };
              openGraph: {
                src: string;
                width: number;
                height: number;
              };
            };
          };
          focalPoint: {
            x: number;
            y: number;
          };
          alt?: string;
        };
        heading: string;
        subheading: string;
        description: string;
        categories: {
          title: string;
          subtitle?: string;
          schedules?: {
            startDate: string;
            endDate: string;
            location: string;
            event: string;
          }[];
        }[];
      };
    };
  };
}

export function SchedulePageTemplate({
  title,
  location,
  schema,
  image,
  focalPoint,
  heading,
  subheading,
  categories,
}: TemplateProps) {
  return (
    <Layout location={location} title={title} schema={schema}>
      <Hero
        image={image}
        focalPoint={focalPoint}
        heading={heading}
        subheading={subheading}
      />
      {categories &&
        categories.map(category => (
          <section key={v4()} className="section">
            <div className="container">
              <h1 className="title">{category.title}</h1>
              {category.subtitle && (
                <h2 className="subtitle">{category.subtitle}</h2>
              )}
              {category.schedules && category.schedules.length > 0 && (
                <ol className="schedule">
                  {category.schedules.map(schedule => (
                    <li key={v4()} className="schedule-item">
                      <div>
                        <EventDate
                          startDate={schedule.startDate}
                          endDate={schedule.endDate}
                        />
                      </div>
                      <div>{schedule.location}</div>
                      <div>{schedule.event}</div>
                    </li>
                  ))}
                </ol>
              )}
            </div>
          </section>
        ))}
    </Layout>
  );
}

export default function SchedulePage({ data, location }: PageProps) {
  const { frontmatter } = data.markdownRemark;
  const { hero, openGraph } = frontmatter.heroImage.image.childImageSharp;
  const mimeType = getMimeTypeFromExtension(openGraph.src);

  return (
    <>
      <Helmet>
        <title>{frontmatter.title}</title>
        <meta name="description" content={frontmatter.description} />
        <meta
          property="og:image"
          content={`http://${location.hostname}${location.port !== '80' &&
            `:${location.port}`}${openGraph.src}`}
        />
        <meta
          property="og:image:secure_url"
          content={`https://${location.hostname}${location.port !== '443' &&
            `:${location.port}`}${openGraph.src}`}
        />
        {mimeType && <meta property="og:image:type" content={mimeType} />}
        <meta property="og:image:width" content={`${openGraph.width}`} />
        <meta property="og:image:height" content={`${openGraph.height}`} />
        {frontmatter.heroImage.alt && (
          <meta property="og:image:alt" content={frontmatter.heroImage.alt} />
        )}
      </Helmet>
      <SchedulePageTemplate
        title={frontmatter.title}
        location={location.href}
        schema={{
          '@type': frontmatter.type,
          name: frontmatter.title,
          description: frontmatter.description,
          url: location.href,
          primaryImageOfPage: {
            '@type': 'ImageObject',
            contentUrl: hero.src,
            width: hero.width,
            height: hero.height,
            ...(frontmatter.heroImage.alt && {
              description: frontmatter.heroImage.alt,
            }),
          },
          headline: frontmatter.heading,
          alternativeHeadline: frontmatter.subheading,
          mainContentOfPage: {
            '@type': 'Table',
            about: 'Terminliste',
          },
        }}
        image={hero}
        focalPoint={frontmatter.heroImage.focalPoint}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        categories={frontmatter.categories}
      />
    </>
  );
}

export const schedulePageQuery = graphql`
  query SchedulePageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        type
        title
        heroImage {
          alt
          focalPoint {
            x
            y
          }
          image {
            childImageSharp {
              hero: fluid(maxWidth: 2048) {
                ...GatsbyImageSharpFluid_noBase64
                width: presentationWidth
                height: presentationHeight
              }
              openGraph: resize(width: 1200, height: 1200) {
                src
                width
                height
              }
            }
          }
        }
        heading
        subheading
        description
        categories {
          title
          subtitle
          schedules {
            startDate
            endDate
            location
            event
          }
        }
      }
    }
  }
`;
